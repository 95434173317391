import React, { FC } from "react";
import { useSelector } from "react-redux";
import { StateType } from "../../store/types";
import { CustomerObject } from "../../store/customer/types";
import TableFilter from "../../components/TableFilter/TableFilter";

const Customers: FC = () => {
  const customers = useSelector(
    (state: StateType) => state.customerReducer.customers
  );

  const contentArr = customers.map((item: CustomerObject) => [
    item.CustomerName,
    item.TYPO3Update ? "Ja" : "Nein",
    item.Support ? "Ja" : "Nein",
    item.Hosting,
    item.id,
  ]);

  const titles = ["Kunde", "Update?", "Support?", "Hosting"];

  return (
    <>
      <TableFilter
        titles={titles}
        contentArr={contentArr}
        detailSite={"customers"}
      />
    </>
  );
};

export default Customers;
