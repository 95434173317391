import React, { FC } from "react";
import { useStyletron } from "baseui";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { graphqlRequestGetLoginResolved } from "../../store/login/actions";

const LoginButton: FC = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch()

  if (location.hash.includes("access_token")) {
    const accessToken = location.hash.split('#access_token=')[1].split('&')[0];

    axios.get('https://gitlab.rheinschafe.de/api/v4/user?access_token='+accessToken).then((response: { data: any; }) => {
      dispatch(graphqlRequestGetLoginResolved(response.data))
    });
  }
  const [css] = useStyletron();

  return (
    <button
      className={css({
        padding: "50px",
        fontSize: "100px",
        background: "#222222",
        color: "white",
        marginLeft: "calc(50% - 175px)"
      })}
      onClick={() => {
        window.location.href = `https://gitlab.rheinschafe.de/oauth/authorize?client_id=${process.env.REACT_APP_GITLAB_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=token&state=${Date.now()}&scope=read_user`;
        return null;
      }}
    >
      {children}
    </button>
  );
};

export default LoginButton;
