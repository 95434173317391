import React, { FC } from "react";
import { useStyletron } from "baseui";

export const Inner: FC<{}> = ({ children }) => {
  const [css] = useStyletron();

  return (
    <h2
      className={css({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        padding: ".25rem",
      })}
    >
      {children}
    </h2>
  );
};
