import axios from "axios";

interface Action {
  type: string;
  payload?: any;
}

export default ({ dispatch }: { dispatch: (action: Action) => Action }) => (
  next: (action: Action) => Action
) => (action: { type: string; payload: { query: string } }) => {
  if (!action.type.includes("GRAPHQL_REQUEST")) {
    return next(action);
  } else if (action.payload !== undefined) {
    const { query } = action.payload;
    if (query !== undefined) {
      axios
        .create({
          baseURL: process.env.REACT_APP_API_BASE_STAGE,
        })
        .post("", {
          query,
        })
        .then(({ data }) => {
          return data.data;
        })
        .then((data) => {
          if (
            data === undefined ||
            data.login === null ||
            data.check === null
          ) {
            dispatch({ type: "LOGIN_FAILED" });
          } else {
            dispatch({ type: action.type + "_RESOLVED", payload: data });
          }
        });
    }
    return next(action);
  }
};
