import {
  InstancesState,
  InstancesActionTypes,
  GRAPHQL_REQUEST_GET_INSTANCES_RESOLVED,
} from "./types";

const initialState: InstancesState = {
  instances: [],
};

export default (
  state = initialState,
  action: InstancesActionTypes
): InstancesState => {
  switch (action.type) {
    case GRAPHQL_REQUEST_GET_INSTANCES_RESOLVED:
      return {
        ...state,
        instances: action.payload.instances,
      };
    default:
      return state;
  }
};
