import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import LoginButton from "../../components/LoginButton/LoginButton";
import { StateType } from "../../store/types";


const Login = () => {
  const developer = useSelector(
    (state: StateType) => state.loginReducer.developer
  );
  return developer.length === 0 ? (
    <LoginButton>Login</LoginButton>
  ) : (
    <Redirect to="/myInstances"/>
  );
};

export default Login;
