import React, { FC } from "react";
import { useSelector } from "react-redux";
import { InstancesObject } from "../../store/instances/types";
import { StateType } from "../../store/types";
import TableFilter from "../../components/TableFilter/TableFilter";

const MyInstances: FC = () => {
  const instances = useSelector(
    (state: StateType) => state.instancesReducer.instances
  );

  const developer = useSelector(
    (state: StateType) => state.loginReducer.developer
  );

  const titles = [
    "Title",
    "Link",
    "Software",
    "Type",
    "Version",
    "Kunde",
    "Update",
    "Support",
  ];

  const contentArr = instances
    .filter(
      (item: InstancesObject) =>
        item.developer && item.developer.Name === developer
    )
    .map((item: InstancesObject) => [
      item.Title,
      item.LinkFrontend,
      item.Software,
      item.Type,
      item.Version,
      item.relatedCustomer && item.relatedCustomer.CustomerName,
      item.relatedCustomer && item.relatedCustomer.TYPO3Update ? "Ja" : "Nein",
      item.relatedCustomer && item.relatedCustomer.Support ? "Ja" : "Nein",
      item.id,
    ]);

  return (
    <TableFilter
      titles={titles}
      contentArr={contentArr}
      detailSite={"instances"}
    />
  );
};

export default MyInstances;
