import {
  CustomerState,
  CustomersActionTypes,
  GRAPHQL_REQUEST_GET_CUSTOMERS_RESOLVED,
} from "./types";

const initialState: CustomerState = {
  customers: [],
};

export default (
  state = initialState,
  action: CustomersActionTypes
): CustomerState => {
  switch (action.type) {
    case GRAPHQL_REQUEST_GET_CUSTOMERS_RESOLVED:
      return {
        ...state,
        customers: action.payload.customers,
      };
    default:
      return state;
  }
};
