export const GRAPHQL_REQUEST_GET_CUSTOMERS = "GRAPHQL_REQUEST_GET_CUSTOMERS";
export const GRAPHQL_REQUEST_GET_CUSTOMERS_RESOLVED =
  "GRAPHQL_REQUEST_GET_CUSTOMERS_RESOLVED";

export type CustomerObject = {
  CustomerName: string;
  modifiedDate: string;
  TYPO3Update: Boolean;
  Support: Boolean;
  Hosting: string;
  id: string;
  created_at: Date;
  updated_at: Date;
};

export interface CustomerState {
  customers: CustomerObject[];
}

interface GetCustomers {
  type: typeof GRAPHQL_REQUEST_GET_CUSTOMERS;
  payload: { query: string };
}

interface GetCustomersResolved {
  type: typeof GRAPHQL_REQUEST_GET_CUSTOMERS_RESOLVED;
  payload: { customers: CustomerObject[] };
}

export type CustomersActionTypes = GetCustomers | GetCustomersResolved;
