import React, { useEffect } from "react";
import { Grid, Cell } from "baseui/layout-grid";
import Navigation from "../../components/Navigation/Navigation";
import { BrowserRouter, Link } from "react-router-dom";
import { Outer, Title } from "./styles/RootStyles";
import Routing from "../../components/Routing/Routing";
import { graphqlRequestGetInstances } from "../../store/instances/actions";
import { useDispatch, useSelector } from "react-redux";
import { graphqlRequestGetCustomers } from "../../store/customer/actions";
import { StateType } from "../../store/types";

const Root = () => {
  const dispatch = useDispatch();
  const checkLoginDeveloper = useSelector((state: StateType) => state.loginReducer.developer)

  const pages = [
    { title: "Meine Instanzen", itemId: "/myInstances" },
    { title: "Kunden", itemId: "/customers" },
    { title: "Alle Instanzen", itemId: "/allInstances" },
  ];

  const query = {
    instances: `{
      instances {
        id,
        Title,
        Type,
        LinkFrontend,
        Provider,
        Software,
        Complexity,
        server {
          Name
        },
        Comment,
        gitLink,
        Version,
        developer {
          Name
        },
        relatedCustomer {
          CustomerName,
          TYPO3Update,
          Support
        }
        DevEnvironment,
        AutomaticDeployment,
        rscwKit,
        oauth,
        Monitoring,
        automaticTests,
        AccountManager {
          Name
        },
        htaccesUser,
      }
    }`,
    customer: `{
      customers {
        id,
        CustomerName,
        TYPO3Update,
        Support,
        Hosting
      }
    }`,
  };

  useEffect(() => {
    if (checkLoginDeveloper && checkLoginDeveloper.length > 0) {
      dispatch(graphqlRequestGetInstances(query.instances));
      dispatch(graphqlRequestGetCustomers(query.customer));
    }
  }, [dispatch, query, checkLoginDeveloper]);

  return (
    <BrowserRouter>
      <Outer>
        <Grid gridMargins={[0]} gridMaxWidth={4000}>
          <Cell span={12}>
            <div style={{ margin: "3rem" }}>
              <Link to="/" style={{textDecoration: "none"}}><Title>RSCW Instance Manager</Title></Link>
            </div>
          </Cell>
          <Cell span={2}>
            <Navigation pages={pages} />
          </Cell>
          <Cell span={10}>
            <Routing pages={pages} />
          </Cell>
        </Grid>
      </Outer>
    </BrowserRouter>
  );
};

export default Root;
