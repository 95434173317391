import { combineReducers } from "redux";

import instancesReducer from "./instances/reducer";
import loginReducer from "./login/reducer";
import customerReducer from "./customer/reducer";

const rootReducer = combineReducers({
  instancesReducer: instancesReducer,
  loginReducer: loginReducer,
  customerReducer: customerReducer,
});

export default rootReducer;
