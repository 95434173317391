import React, { FC } from "react";
import { useStyletron } from "baseui";

export const InnerText: FC<{}> = ({ children }) => {
  const [css] = useStyletron();

  return (
    <p
      className={css({
        display: "flex",
        color: "white",
        padding: ".25rem",
        marginBottom: "3rem",
      })}
    >
      {children}
    </p>
  );
};

export const InnerTitle: FC<{}> = ({ children }) => {
  const [css] = useStyletron();

  return (
    <h2
      className={css({
        display: "flex",
        color: "white",
        padding: ".25rem",
      })}
    >
      {children}
    </h2>
  );
};

export const Button: FC<{ f: () => void }> = ({ f, children }) => {
  const [css] = useStyletron();

  return (
    <button
      className={css({
        padding: "10px",
        background: "#222222",
        color: "white",
        marginTop: "3rem",
      })}
      onClick={() => f()}
    >
      {children}
    </button>
  );
};
