import {
  LoginState,
  LoginActionTypes,
  GRAPHQL_REQUEST_GET_LOGIN,
} from "./types";

const initialState: LoginState = {
  developer: "",
  accessToken: null,
};

export default (state = initialState, action: LoginActionTypes): LoginState => {
  switch (action.type) {
    case GRAPHQL_REQUEST_GET_LOGIN + "_RESOLVED":
      return {
        ...state,
        developer: action.payload.name
      };
    default:
      return state;
  }
};
