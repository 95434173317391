import { createStore, applyMiddleware, compose } from "redux";
import allReducers from "./reducers";
import graphqlMiddleware from "./middleware/graphQLMiddleware";

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(applyMiddleware(graphqlMiddleware));

export default createStore(allReducers, enhancer);
