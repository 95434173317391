export const GRAPHQL_REQUEST_GET_LOGIN = "GRAPHQL_REQUEST_GET_LOGIN";
export const GRAPHQL_REQUEST_GET_LOGIN_RESOLVED = "GRAPHQL_REQUEST_GET_LOGIN_RESOLVED";

export interface LoginState {
  developer: string;
  accessToken: string | null;
}

interface GetLogin {
  type: typeof GRAPHQL_REQUEST_GET_LOGIN;
  payload: {data: any}
}

interface GetLoginResolved {
  type: typeof GRAPHQL_REQUEST_GET_LOGIN_RESOLVED;
  payload: any
}

export type LoginActionTypes = GetLogin | GetLoginResolved;
