import React, { FC } from "react";
import { useSelector } from "react-redux";
import { InstancesObject } from "../../store/instances/types";
import { StateType } from "../../store/types";
import { Grid, Cell } from "baseui/layout-grid";
import { InnerText, InnerTitle, Button } from "./styles/DetailStyles";
import { useParams, useHistory } from "react-router-dom";
import { CustomerObject } from "../../store/customer/types";
import TableFilter from "../../components/TableFilter/TableFilter";

const Detail: FC = () => {
  const params: { id: string; type: string } = useParams();
  const getID = Number(params.id) - 1;

  const history = useHistory();

  const instances = useSelector(
    (state: StateType) => state.instancesReducer.instances
  );

  const customer = useSelector(
    (state: StateType) => state.customerReducer.customers
  );

  const tableContent: {
    [key: string]: any[][];
  } = {
    instances: instances.map((item: InstancesObject) => [
      item.Title,
      item.Type,
      item.LinkFrontend,
      item.Provider,
      item.Software,
      item.Complexity,
      item.server && item.server.Name,
      item.Comment,
      item.gitLink,
      item.Version,
      item.developer && item.developer.Name,
      item.relatedCustomer && item.relatedCustomer.CustomerName,
      item.relatedCustomer && item.relatedCustomer.TYPO3Update ? "Ja" : "Nein",
      item.relatedCustomer && item.relatedCustomer.Support ? "Ja" : "Nein",
      item.DevEnvironment,
      item.AutomaticDeployment ? "Ja" : "Nein",
      item.rscwKit ? "Ja" : "Nein",
      item.oauth ? "Ja" : "Nein",
      item.Monitoring ? "Ja" : "Nein",
      item.automaticTests ? "Ja" : "Nein",
      item.AccountManager && item.AccountManager.Name,
      item.htaccesUser,
    ]),
    customers: customer.map((item: CustomerObject) => [
      item.CustomerName,
      item.TYPO3Update ? "Ja" : "Nein",
      item.Support ? "Ja" : "Nein",
      item.Hosting,
    ]),
  };

  const titles: { [key: string]: string[] } = {
    instances: [
      "Title",
      "Type",
      "LinkFrontend",
      "Provider",
      "Software",
      "Complexity",
      "Servername",
      "Comment",
      "gitLink",
      "Version",
      "developer",
      "Kunde",
      "Update?",
      "Support?",
      "DevEnvironment",
      "AutomaticDeployment",
      "rscwKit",
      "oauth",
      "Monitoring",
      "automaticTests",
      "AccountManager",
      "htaccessUser",
    ],
    customers: ["Kunde", "Update?", "Support?", "Hosting"],
  };

  const content = tableContent[params.type];
  const typeTitles = titles[params.type];

  const detailTable = tableContent.instances
    .filter((item) => item[11] === content[getID][0])
    .map((item) => [
      item[0],
      item[2],
      item[4],
      item[1],
      item[9],
      item[12],
      item[13],
      item[10],
      "",
    ]);

  return (
    <>
      <Grid gridMargins={[0]} gridMaxWidth={4000}>
        {typeTitles.map((title: string, i: number) => (
          <Cell span={4}>
            <InnerTitle>{title}</InnerTitle>
            <InnerText>{content[getID] && content[getID][i]}</InnerText>
          </Cell>
        ))}
        {params.type === "customers" && (
          <Cell span={12}>
            <InnerTitle>Instanzen</InnerTitle>
            <TableFilter
              titles={[
                "Title",
                "Link",
                "Software",
                "Type",
                "Version",
                "Update?",
                "Support?",
                "Developer",
              ]}
              contentArr={detailTable}
            />
          </Cell>
        )}
        <Cell skip={4} span={8}>
          <Button f={() => history.goBack()}>Zurück</Button>
        </Cell>
      </Grid>
    </>
  );
};

export default Detail;
