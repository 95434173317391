import React from "react";
import { Navigation } from "baseui/side-navigation";
import { useHistory, useLocation } from "react-router-dom";

const Nav = ({ pages }: { pages: { title: string; itemId: string }[] }) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <Navigation
      items={pages}
      activeItemId={location.pathname}
      onChange={({ event, item }) => {
        event.preventDefault();
        history.push(item.itemId);
      }}
    />
  );
};

export default Nav;
