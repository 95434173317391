import {
  GRAPHQL_REQUEST_GET_INSTANCES,
  InstancesActionTypes,
  GRAPHQL_REQUEST_GET_INSTANCES_RESOLVED,
  InstancesObject,
} from "./types";

export const graphqlRequestGetInstances = (
  data: string
): InstancesActionTypes => ({
  type: GRAPHQL_REQUEST_GET_INSTANCES,
  payload: { query: data },
});

export const graphqlRequestGetInstancesResolved = (data: {
  instances: InstancesObject[];
}): InstancesActionTypes => ({
  type: GRAPHQL_REQUEST_GET_INSTANCES_RESOLVED,
  payload: data,
});
