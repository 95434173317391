import React from "react";
import ReactDOM from "react-dom";
import Root from "./container/Root/Root";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { DarkTheme, ThemeProvider } from "baseui";
import { Provider } from "react-redux";
import store from "./store/store";

const engine = new Styletron();

const App = () => (
  <Provider store={store}>
    <StyletronProvider value={engine}>
      <ThemeProvider theme={DarkTheme}>
        <Root />
      </ThemeProvider>
    </StyletronProvider>
  </Provider>
);

ReactDOM.render(<App />, document.getElementById("root"));
