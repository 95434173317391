import { Developer } from "../developer/types";
import { CustomerObject } from "../customer/types";

export const GRAPHQL_REQUEST_GET_INSTANCES = "GRAPHQL_REQUEST_GET_INSTANCES";
export const GRAPHQL_REQUEST_GET_INSTANCES_RESOLVED =
  "GRAPHQL_REQUEST_GET_INSTANCES_RESOLVED";

enum ENUM_INSTANCES_PROVIDER {
  "einsundeins",
  "AllInklcom",
  "Conversis",
  "DomainFactory",
  "HostEurope",
  "Hetzner",
  "Individuell",
  "Mittwald",
  "Rackspeed",
  "Server4You",
  "Strato",
}

enum ENUM_INSTANCES_SOFTWARE {
  "TYPO3",
  "Laravel",
  "ResourceSpace",
  "Statisch",
  "phpBB",
  "Wordpress",
  "IndividualPHP",
  "Individual",
  "React",
}

enum ENUM_INSTANCES_COMPLEXITY {
  "easy",
  "normal",
  "hard",
  "irrelevant",
}

enum ENUM_INSTANCES_DEVENVIRONMENT {
  "Docker",
  "Server",
  "Local",
  "None",
}

enum ENUM_INSTANCES_TYPE {
  "live",
  "stage",
  "dev",
  "local",
}

export type InstancesObject = {
  Title: string;
  Type: ENUM_INSTANCES_TYPE;
  LinkFrontend: string;
  Provider: ENUM_INSTANCES_PROVIDER;
  Software: ENUM_INSTANCES_SOFTWARE;
  Complexity: ENUM_INSTANCES_COMPLEXITY;
  server: {
    Name: string;
    id: string;
    created_at: Date;
    updated_at: Date;
  };
  Comment: string;
  gitLink: string;
  Version: string;
  developer: Developer;
  relatedCustomer: CustomerObject;
  DevEnvironment: ENUM_INSTANCES_DEVENVIRONMENT;
  AutomaticDeployment: Boolean;
  rscwKit: Boolean;
  oauth: Boolean;
  Monitoring: Boolean;
  automaticTests: Boolean;
  AccountManager: Developer;
  htaccesUser: string;
  htaccessPassword: string;
  id: string;
  created_at: Date;
  updated_at: Date;
};

export interface InstancesState {
  instances: InstancesObject[];
}

interface GetInstances {
  type: typeof GRAPHQL_REQUEST_GET_INSTANCES;
  payload: { query: string };
}

interface GetInstancesResolved {
  type: typeof GRAPHQL_REQUEST_GET_INSTANCES_RESOLVED;
  payload: { instances: InstancesObject[] };
}

export type InstancesActionTypes = GetInstances | GetInstancesResolved;
