import {
  GRAPHQL_REQUEST_GET_CUSTOMERS,
  CustomersActionTypes,
  GRAPHQL_REQUEST_GET_CUSTOMERS_RESOLVED,
  CustomerObject,
} from "./types";

export const graphqlRequestGetCustomers = (
  data: string
): CustomersActionTypes => ({
  type: GRAPHQL_REQUEST_GET_CUSTOMERS,
  payload: { query: data },
});

export const graphqlRequestGetCustomersResolved = (data: {
  customers: CustomerObject[];
}): CustomersActionTypes => ({
  type: GRAPHQL_REQUEST_GET_CUSTOMERS_RESOLVED,
  payload: data,
});
