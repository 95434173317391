import React, { FC } from "react";
import { useStyletron } from "baseui";

export const Outer: FC<{}> = ({ children }) => {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        background: theme.colors.backgroundAlwaysDark,
        minHeight: "100vh",
        fontFamily: "system-ui, Helvetica Neue, Helvetica, Arial, sans-serif",
        margin: "-8px",
      })}
    >
      {children}
    </div>
  );
};

export const Title: FC<{}> = ({ children }) => {
  const [css] = useStyletron();

  return (
    <h1
      className={css({
        color: "white",
        display: "inline",
      })}
    >
      {children}
    </h1>
  );
};

export const Button: FC<{ f: () => void }> = ({ f, children }) => {
  const [css] = useStyletron();

  return (
    <button
      className={css({
        padding: "10px",
        background: "#222222",
        color: "white",
        float: "right",
      })}
      onClick={() => f()}
    >
      {children}
    </button>
  );
};
