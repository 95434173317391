import React, { FC } from "react";
import { useSelector } from "react-redux";
import { InstancesObject } from "../../store/instances/types";
import { StateType } from "../../store/types";
import TableFilter from "../../components/TableFilter/TableFilter";

const AllInstances: FC = () => {
  const instances = useSelector(
    (state: StateType) => state.instancesReducer.instances
  );

  const contentArr = instances.map((item: InstancesObject) => [
    item.Title,
    item.LinkFrontend,
    item.Software,
    item.Type,
    item.Version,
    item.relatedCustomer && item.relatedCustomer.CustomerName,
    item.relatedCustomer && item.relatedCustomer.TYPO3Update ? "Ja" : "Nein",
    item.relatedCustomer && item.relatedCustomer.Support ? "Ja" : "Nein",
    item.developer && item.developer.Name,
    item.id,
  ]);

  const titles = [
    "Title",
    "Link",
    "Software",
    "Type",
    "Version",
    "Kunde",
    "Update?",
    "Support?",
    "Developer",
  ];

  return (
    <>
      <TableFilter
        titles={titles}
        contentArr={contentArr}
        detailSite={"instances"}
      />
    </>
  );
};

export default AllInstances;
