import React, { useEffect, useState } from "react";
import { Route, useLocation } from "react-router-dom";
import { Inner } from "./styles/RoutingStyles";
import Login from "../../container/Login/Login";
import MyInstances from "../../container/MyInstances/MyInstances";
import Customers from "../../container/Customers/Customers";
import AllInstances from "../../container/AllInstances/AllInstances";
import Detail from "../../container/Detail/Detail";
import PrivateRoute from "../PrivateRoute/PrivateRoute";

const Routing = ({ pages }: { pages: { title: string; itemId: string }[] }) => {
  const location = useLocation();
  const [title, setTitle] = useState("");

  useEffect(() => {
    const currentPage = pages.filter(
      (item: { itemId: string }) => item.itemId === window.location.pathname
    )[0];
    setTitle(currentPage ? currentPage.title : "");
  }, [location, pages]);

  return (
    <>
      <Inner>{title}</Inner>
      <Route exact path="/" component={Login} />
      <PrivateRoute path="/myInstances" component={MyInstances} />
      <PrivateRoute path="/customers" component={Customers} />
      <PrivateRoute path="/allInstances" component={AllInstances} />
      <PrivateRoute path="/:type/details/:id" component={Detail} />
    </>
  );
};

export default Routing;
