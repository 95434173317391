import React, { useEffect, useState } from "react";
import {
  SORT_DIRECTION,
  StyledTable,
  SortableHeadCell,
  StyledBodyCell,
} from "baseui/table-grid";
import InputFilter from "../InputFilter/InputFilter";
import { Link } from "react-router-dom";
import { Cell, Grid } from "baseui/layout-grid";

const TableFilter = ({
  titles,
  contentArr,
  detailSite,
}: {
  titles: string[];
  contentArr: any[][];
  detailSite?: string;
}) => {
  const [content, setContent] = useState(contentArr);

  useEffect(() => {
    if (contentArr !== content) {
      setContent(contentArr)
    }
  }, [contentArr])

  type Direction = {
    [key: string]: SORT_DIRECTION | null;
  };

  const [direction, setDirection] = useState<Direction>(
    titles.reduce(
      (acc: Direction, cur: string) => ({
        ...acc,
        [cur.toLowerCase() + "Direction"]: null,
      }),
      {}
    )
  );

  const handleSort = (
    title: string,
    prevDirection: string | null | undefined
  ) => {
    let nextDirection: SORT_DIRECTION | null = null;
    if (prevDirection === SORT_DIRECTION.ASC) {
      nextDirection = SORT_DIRECTION.DESC;
    }
    if (prevDirection === SORT_DIRECTION.DESC) {
      nextDirection = null;
    }
    if (prevDirection === null) {
      nextDirection = SORT_DIRECTION.ASC;
    }
    titles.forEach((item) => {
      if (title === item.toLowerCase()) {
        setDirection(
          titles.reduce(
            (acc: Direction, cur: string) => ({
              ...acc,
              [cur.toLowerCase() + "Direction"]:
                cur.toLowerCase() === title ? nextDirection : null,
            }),
            {}
          )
        );
        return;
      }
    });
  };

  const getSortedData = () => {
    let sortReturn = null;
    titles.forEach((item, i) => {
      const key = item.toLowerCase() + "Direction";
      if (direction[key]) {
        const sorted = [
          ...content
            .filter((item) => item[i] !== null)
            .slice(0)
            .sort((a, b) => a[i].localeCompare(b[i])),
          ...content.filter((item) => item[i] === null),
        ];
        if (direction[key] === SORT_DIRECTION.ASC) {
          sortReturn = sorted;
        }
        if (direction[key] === SORT_DIRECTION.DESC) {
          sortReturn = sorted.reverse();
        }
      }
    });
    return sortReturn ? sortReturn : content ? content : [];
  };

  const columns = "repeat(" + titles.length + ",1fr)";

  return (
    <Grid gridMargins={[0]} gridMaxWidth={4000}>
      <Cell skip={9} span={3}>
        <InputFilter content={contentArr} filterFunc={setContent} />
      </Cell>
      <Cell span={12}>
        <StyledTable role="grid" $gridTemplateColumns={columns}>
          <div role="row" style={{ display: "contents" }}>
            {titles.map((title) => (
              <SortableHeadCell
                title={title}
                direction={direction[title.toLowerCase() + "Direction"]}
                onSort={() =>
                  handleSort(
                    title.toLowerCase(),
                    direction[title.toLowerCase() + "Direction"]
                  )
                }
              />
            ))}
          </div>
          {titles.map((title, index) => (
            <StyledBodyCell key={index}>
              <InputFilter
                content={contentArr}
                filterFunc={setContent}
                index={index}
              />
            </StyledBodyCell>
          ))}
          {getSortedData().map((row) => (
            <div role="row" style={{ display: "contents" }}>
              {row.map(
                (cell, cellIndex) =>
                  cell !== row[row.length - 1] && (
                    <StyledBodyCell key={cellIndex}>
                      {detailSite ? (
                        <Link
                          to={`${detailSite}/details/${row[row.length - 1]}`}
                          style={{ color: "white", textDecoration: "none" }}
                        >
                          {cell}
                        </Link>
                      ) : (
                        cell
                      )}
                    </StyledBodyCell>
                  )
              )}
            </div>
          ))}
        </StyledTable>
      </Cell>
    </Grid>
  );
};

export default TableFilter;
