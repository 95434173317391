import React, { useState, Dispatch, SetStateAction, FormEvent } from "react";
import { Input } from "baseui/input";

const InputFilter = ({
  content,
  filterFunc,
  index,
}: {
  content: any[][];
  filterFunc: Dispatch<SetStateAction<any[][]>>;
  index?: number;
}) => {
  const [value, setValue] = useState<string | undefined>();

  const applyFilter = (e: FormEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    if (value.length > 0) {
      filterFunc(
        content.filter((item: string[]) =>
        index === undefined
            ? item.some(
                (columns) =>
                  columns &&
                  value &&
                  columns.toLowerCase().includes(value.toLowerCase())
              )
            : item[index] && item[index].toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      filterFunc(content)
    }
    setValue(value);
  };

  return (
    <div style={{ marginBottom: index === undefined ? "20px" : "0" }}>
      <Input
        value={value}
        onChange={(e) => applyFilter(e)}
        placeholder="Search"
        clearOnEscape
      />
    </div>
  );
};

export default InputFilter;
