import React, { FC } from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({
  component: Component,
  path,
}: {
  component: FC;
  path: string;
}): any => {
  const accessToken = "undefined";
  if (accessToken !== undefined) {
    return <Route exact path={path} component={Component} />;
  } else {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }
};

export default PrivateRoute;
